//File: insertcancelledserialnumber.component.ts
//Created By: pradeep valiya on 02.12.2022
//Copyright (C) 2022-Present, Octanics Software LLP. All rights reserved.
import {Component, OnInit} from '@angular/core';
import {Http, Response} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {Lookup} from "../../../common/lookup";
import {AlertService} from "../../../common/alert.service";
import {ValidationerrorModule} from "@app/validationerror/validationerror.module";
import {HttpClient} from "@angular/common/http";

declare var $: any;

@Component({
  selector: 'app-insertcancelledserialnumber',
  templateUrl: './insertcancelledserialnumber.component.html',
  styleUrls: ['./insertcancelledserialnumber.component.scss']
})
export class InsertcancelledserialnumberComponent implements OnInit {

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute,
              private fb: FormBuilder, private lookUp: Lookup, private alertService: AlertService) {
  }

  year = [];
  months :any= [];
  cancelledSerialNumberForm: FormGroup;

  month_id: any;
  year_id: any;
  type: any;

  getLookUp() {
    this.lookUp.getCancelledserialnumberLookUp('year').subscribe((data) => {
      if (data) {
        this.year = data;
      }
    });
    this.lookUp.getCancelledserialnumberLookUp('months').subscribe((data) => {
      if (data) {
        this.months = data;
      }
    });
  }

  insertCancelledSerialNumber(cancelledSerialNumber) {
    if (this.cancelledSerialNumberForm.valid) {
      cancelledSerialNumber.type = this.type;
      cancelledSerialNumber.active = true;
      cancelledSerialNumber.created_by = Utility.getLoggedUserId();
      cancelledSerialNumber.modified_by = Utility.getLoggedUserId();
      this.http.post(Configuration.serviceCoreURL + "/insertcancelledserialnumber", cancelledSerialNumber).subscribe((res: Response) => {
        this.router.navigate(['/cancelledserialnumber'], {queryParams: {type: this.type}});
      });
    } else {
      this.alertService.validationAlert();
    }
  }

  ngOnInit() {
    this.getLookUp();
    this.cancelledSerialNumberForm = new FormGroup({
      serialnumber: new FormControl('', Validators.required),
      month_id: new FormControl('', Validators.required),
      year_id: new FormControl('', Validators.required),
    });
    this.route.queryParams.subscribe(params => {
      this.type = params['type'];
      // console.log(this.type);
    });
  }
}
