import {Component, OnInit} from '@angular/core';
import {Http, Response} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Lookup} from 'app/common/lookup';

declare var $: any;

@Component({
    selector: 'app-salesemailtemplate',
    templateUrl: './salesemailtemplate.component.html',
    styleUrls: ['./salesemailtemplate.component.scss']
})
export class SalesemailtemplateComponent implements OnInit {

  constructor(private http: Http, private router: Router, private title: Title, private route: ActivatedRoute, private lookUp: Lookup) {
  }

    templateEmailDetail:any = [];

    public options:any = {
        toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
            'insertTable', 'insertLink'],
        toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
            'insertTable', 'insertLink'],
        toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
            'insertTable', 'insertLink'],
        toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'align', 'formatOL', 'outdent', 'indent', '-', 'insertImage', 'embedly',
            'insertTable', 'insertLink'],
    };
    templateSmsDetail:any = [];
    email;
  emailtext;
    value: any;
    communicationmodes: any = [];
    templates: any = [];
    Utility: any = Utility;
    templateForm: FormGroup;
  communicationmodeId: any;
  communicationmodeName: any;

    getLookUp () {
        this.lookUp.getSalesSetUplookup('communicationmodes').subscribe((data) => {if (data) {this.communicationmodes = data;}});
        this.lookUp.getSalesSetUplookup('salestemplates').subscribe((data) => {if (data) {this.templates = data;}});
    }

  selectCommunicationModeId(args) {
    this.communicationmodeId = args.target.value;

    if (this.communicationmodeId == 'null') {
      $('#emaildesc').show();
      $('#subjectdiv').show();
      $('#smsdesc').hide();
    } else {
      this.http.get(Configuration.serviceCoreURL + '/communicationmodename?id=' + this.communicationmodeId).subscribe(data => {
        this.communicationmodeName = data.json();

        if (this.communicationmodeName == 'SMS') {
          $('#emaildesc').hide();
          $('#subjectdiv').hide();
          $('#smsdesc').show();
        } else {
          $('#emaildesc').show();
          $('#subjectdiv').show();
          $('#smsdesc').hide();
        }
      });
    }
  }

  onChange($event) {
    this.emailtext = $event;
  }

  insertTemplate (data) {
    if (this.emailtext != undefined) {
      data.description = this.emailtext;
    }
    data.modified_by = Utility.getLoggedUserId();
    this.http.post(Configuration.serviceCoreURL + '/inserttemplate', data).subscribe((res: Response) => {
      $('#frmtemplate').get(0).reset();
      this.router.navigate(['salestemplates']);
    });
  }

    ngOnInit() {
        $('.navbar-collapse li.active').removeClass('active');
        $('#menumaster').addClass('active');
        this.getLookUp();
        this.title.setTitle('Octanics - Sales Templates');

      this.templateForm = new FormGroup({
        templatetype_id: new FormControl('', Validators.required),
        communicationmode_id: new FormControl('', null),
        subject: new FormControl('', null),
        description: new FormControl('', Validators.required),
      });
    }
}
