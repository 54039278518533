import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from "@angular/forms";
import {PaginationComponent} from './pagination.component';
import {Ng2SearchPipeModule} from 'ng2-search-filter';

@NgModule({
  declarations: [
    PaginationComponent,
  ],
  exports: [
    PaginationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    Ng2SearchPipeModule,
  ]
})
export class PaginationModule { }
