import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Configuration} from 'assets/general/configuration';
import {Recurringeventconfiguration} from '../../../models/recurringeventconfiguration.model';
import {Common} from '@app/common/common';
import {HttpClient} from "@angular/common/http";
@Component({
  selector: 'app-schedulerjoblist',
  templateUrl: './schedulerjoblist.component.html',
  styleUrls: ['./schedulerjoblist.component.css']
})
export class SchedulerjoblistComponent implements OnInit {

  constructor(private http: HttpClient, private httpclient: HttpClient,  private title: Title) {
  }

  p: any = 1;
  Recurringeventconfiguration: Recurringeventconfiguration;
  schedulerjobconfigurations;
  Common = Common;


  getRecurringeventconfiguration = function () {

    this.http.get(Configuration.serviceCoreURL + '/schedulerjobconfigurations').subscribe(data => {
      this.schedulerjobconfigurations = data;
    });
  };
  insertScheduler (schedule) {
    // schedule.modified_by = Utility.getLoggedUser;
    // schedule.created_by = Utility.getLoggedUser;
    // schedule.employee_id = this.id;
    this.http.post(Configuration.serviceCoreURL + '/insertpayslip', schedule).subscribe((res: Response) => {
      //  this.router.navigate(['/salesorders']);
    });
  }

  queueService(schedule) {
    this.http.post(Configuration.serviceCoreURL + '/queueservice', schedule).subscribe((res: Response) => {
    });
  }


  ngOnInit() {

    $('.navbar-collapse li.active').removeClass('active');
    $('#menubackoffice').addClass('active');

    this.getRecurringeventconfiguration();
    this.title.setTitle('Octanics - Scheduler Job ');
  }

}


