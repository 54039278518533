import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Alert, AlertType } from '../models/alert.model';
import { Router, NavigationStart } from '@angular/router';

export class AlertService {
  private subject = new Subject<Alert>();
  private defaultId = 'default-alert';
  alerts: Alert[] = [];
  private keepAfterRouteChange = false;

/*  constructor(private router: Router) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          this.keepAfterRouteChange = false;
        } else {
          // clear alert messages
          this.clear();
        }
      }
    });
  }*/
  // enable subscribing to alerts observable
  onAlert(id = this.defaultId): Observable<Alert> {
    // @ts-ignore
    return this.subject.asObservable().pipe(filter(x => x && 'default-alert' === id));
  }

  // // convenience methods
  // success(message?: string, options?: any) {
  //   this.alert(new Alert({ ...options, type: AlertType.Success, message }));
  // }

  validationAlert() {
    this.alert(new Alert({}));
  }

  // info(message: string, options?: any) {
  //   this.alert(new Alert({ ...options, type: AlertType.Info, message }));
  // }
  //
  // warn(message: string, options?: any) {
  //   this.alert(new Alert({ ...options, type: AlertType.Warning, message }));
  // }

  // main alert method
  alert(alert: Alert) {
    this.keepAfterRouteChange = alert.keepAfterRouteChange;
    this.subject.next(alert);
  }
  /*alert(alert: Alert) {
    alert.id = alert.id || this.defaultId;
    this.subject.next(alert);
  }*/

  // clear alerts
  clear(alertId?: string) {
    this.subject.next(new Alert({ alertId }));
  }
 /* clear(id = this.defaultId) {
    //this.alerts = [];
    this.subject.next(new Alert({ id }));
  }*/
}
