import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import {Utility} from 'app/common/utility';
@Component({
  selector: 'app-advancedpagination',
  templateUrl: './advancedpagination.component.html',
  styleUrls: ['./advancedpagination.component.css']
})
export class AdvancedpaginationComponent  {
    @Input('page') page: number;
    @Input('total') total: number;
    @Input('totalPages')totalPages: number;
    @Output() pagination = new EventEmitter();
    Utility= Utility;
    pageCount: number;
  constructor() { }
    getTotal(totalPages,total){
        return this.pageCount = Math.ceil(totalPages /total);

    }

    paginationEmit(pag: number) {
        this.pagination.emit({valor: pag});
        this.pageCount = Math.ceil(this.totalPages / this.total);

    }

    moveToNextPage() {
        if (this.page < this.pageCount) {
            this.page++;
            this.paginationEmit(this.page);
            this.pageCount = Math.ceil(this.totalPages / this.total);
        }
    }

    moveToPreviousPage() {
        if (this.page > 1) {
            this.page--;
            this.paginationEmit(this.page);
            this.pageCount = Math.ceil(this.totalPages / this.total);
        }
    }
    goToPage(page) {
        if(page !== '' && !isNaN(this.page) && page !=='0'){
            this.paginationEmit(page);
        }
        else{
            this.page = 1;
            this.paginationEmit(page);
        }
    }

}
