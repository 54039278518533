import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ValidationerrorComponent} from './validationerror.component';

@NgModule({
  declarations: [
    ValidationerrorComponent,
  ],
  exports: [
    ValidationerrorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class ValidationerrorModule { }
