export class Kradetails {
  id:number;
  performance_id:number;
  kra_id:number;
  target:string;
  actual:string;
  pending:string;
  comments:string;
  active:boolean;
  created_by:number;
  created_at: string;
  modified_by:number;
  modified_at: string;
  deleted_by:number;
  deleted_at: string;
}
