import {AfterViewInit, Component} from '@angular/core';
import {ActivatedRoute, Event, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Common} from './common/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    timeout;
    loader = true;
    title = 'app';
    Common=Common;

    constructor(private router: Router, private route: ActivatedRoute) {
        router.events.subscribe((event: Event) => {
            this.navigationInterceptor(event);
        });
    }

    navigationInterceptor(event: Event): void {
        if (event instanceof NavigationStart) {
            this.loader = true;
        }
        if (event instanceof NavigationEnd) {
            this.loader = false;
            Common.PageIndex=0;
        }
        this.route.params.subscribe((res) => {
            if (this.router.url === '/noaccesspage') {
                this.loader = false;
            }
        });

    }
}
