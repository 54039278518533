import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {routing} from './app.routes';
import {AppComponent} from './app.component';
import {AuthGuard} from './auth.guard';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {RouterModule} from '@angular/router';
import {HttpModule} from '@angular/http';
import {Lookup} from "@app/common/lookup";

import {Ng2SearchPipeModule} from 'ng2-search-filter';

import {AlertService} from "@app/common/alert.service";

import {PopupService} from "./common/popup.service";

import {FroalaEditorModule, FroalaViewModule} from "angular-froala-wysiwyg";
import {SidemenuComponent} from './shared/sidemenu/sidemenu.component';
import {SidemenulayoutComponent} from './shared/sidemenulayout/sidemenulayout.component';
import {TokenService} from "@app/common/token.service";
import {AuthService} from "../../auth.service";
import {Authorisation} from "@app/common/authorisation";
import {RedirectComponent} from "@app/redirect/redirect.component";
import {LoginComponent} from "@app/login/login.component";
import {LayoutComponent} from "@app/shared/layout/layout.component";
import {HeaderComponent} from "@app/shared/header/header.component";
import {FooterComponent} from "@app/shared/footer/footer.component";
import {Localstorage} from "@app/common/localstorage";
import {Securestorage} from "@app/common/securestorage";
import {ActivetabService} from "@app/common/activetab.service";
import {CircleProgressOptions, NgCircleProgressModule} from "ng-circle-progress";
import {PaginationModule} from "@app/pagination/pagination.module";
import {AdvancedpaginationModules} from "@app/advancedpagination/advancedpagination.modules";
import {NgbPaginationModule} from "@ng-bootstrap/ng-bootstrap";
import {NgxBarcodeModule} from "ngx-barcode";
import { BarcodeGeneratorAllModule,QRCodeGeneratorAllModule,DataMatrixGeneratorAllModule } from '@syncfusion/ej2-angular-barcode-generator';
import {RoleService} from "@app/common/roleservice";
import {AgmCoreModule} from "@agm/core";

import {CancelledserialnumberlistComponent} from "./sales/cancelledserialnumber/cancelledserialnumberlist/cancelledserialnumberlist.component";
import {CancelledserialnumberviewComponent} from "./sales/cancelledserialnumber/cancelledserialnumberview/cancelledserialnumberview.component";
import {InsertcancelledserialnumberComponent} from "./sales/cancelledserialnumber/insertcancelledserialnumber/insertcancelledserialnumber.component";
import {UpdatecancelledserialnumberComponent} from "./sales/cancelledserialnumber/updatecancelledserialnumber/updatecancelledserialnumber.component";
import {ValidationerrorModule} from "@app/validationerror/validationerror.module";

// import {InsertsalaryComponent} from './finance/costing/salary/insertsalary/insertsalary.component';
// import {UpdatesalaryComponent} from './finance/costing/salary/updatesalary/updatesalary.component';
// import {SalaryviewComponent} from './finance/costing/salary/salaryview/salaryview.component';
import {PagenotfoundComponent} from './errors/pagenotfound/pagenotfound.component';
import {Emailverification} from "@app/common/emailverification";
import {ProcessingcenterlogComponent} from "@app/backoffice/processingcenterlog/processingcenterlog.component";
import {QueueserviceComponent} from "@app/backoffice/queueservice/queueservice.component";
import {SchedulerjoblistComponent} from "@app/backoffice/schedulerjob/schedulerjoblist/schedulerjoblist.component";
import {UpdateschedulerjobComponent} from "@app/backoffice/schedulerjob/updateschedulerjob/updateschedulerjob.component";
import {InsertperformancekraComponent} from "@app/humanresources/performancemanagement/insertperformancekra/insertperformancekra.component";
import {UpdateperformancekraComponent} from "@app/humanresources/performancemanagement/updateperformancekra/updateperformancekra.component";
import {SalesemailtemplateComponent} from "@app/masters/sales/salesemailtemplate/salesemailtemplate.component";
import {ScmemailtemplateComponent} from "@app/masters/scm/scmemailtemplate/scmemailtemplate.component";
import {ScmprocesslistComponent} from "@app/masters/scm/scmprocess/scmprocesslist/scmprocesslist.component";
import {UpdatescmprocessComponent} from "@app/masters/scm/scmprocess/updatescmprocess/updatescmprocess.component";
import {LwfsettingmenuComponent} from "@app/payroll/setup/lwfsettingmenu/lwfsettingmenu.component";
import {InserttravelallowanceComponent} from "@app/payroll/setup/travelallowance/inserttravelallowance/inserttravelallowance.component";
import {TravelallowancelistComponent} from "@app/payroll/setup/travelallowance/travelallowancelist/travelallowancelist.component";
import {TravelallowanceviewComponent} from "@app/payroll/setup/travelallowance/travelallowanceview/travelallowanceview.component";
import {UpdatetravelallowanceComponent} from "@app/payroll/setup/travelallowance/updatetravelallowance/updatetravelallowance.component";
import {UpdatecontactComponent} from "@app/updatecontact/updatecontact.component";
// import {SaleprofitmonitoringreportModule} from "@app/sales/report/saleprofitmonitoringreport/saleprofitmonitoringreport.module";

@NgModule({
    declarations: [
      // SalaryviewComponent,
      // UpdatesalaryComponent,
        PagenotfoundComponent,
      // InsertsalaryComponent,
        AppComponent,
        RedirectComponent,
        LoginComponent,
        LayoutComponent,
        HeaderComponent,
        FooterComponent,
      SidemenulayoutComponent,
      // SaleprofitmonitoringreportModule,
      SidemenuComponent,
      CancelledserialnumberlistComponent,
      CancelledserialnumberviewComponent,
      InsertcancelledserialnumberComponent,
      UpdatecancelledserialnumberComponent,
      PagenotfoundComponent,
      /* following components are not in use cusrrently. this modules are added because ng build --prod  command throws error while creating build.*/
      ProcessingcenterlogComponent,
      QueueserviceComponent,
      SchedulerjoblistComponent,
      UpdateschedulerjobComponent,
      InsertperformancekraComponent,
      UpdateperformancekraComponent,
      SalesemailtemplateComponent,
      ScmemailtemplateComponent,
      ScmprocesslistComponent,
      UpdatescmprocessComponent,
      LwfsettingmenuComponent,
      InserttravelallowanceComponent,
      TravelallowancelistComponent,
      TravelallowanceviewComponent,
      UpdatetravelallowanceComponent,
      UpdatecontactComponent,
      /*end of unused component*/
    ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    FormsModule,
    RouterModule,
    routing,
    Ng2SearchPipeModule,
   NgCircleProgressModule.forRoot({
          // set defaults here
          radius: 100,
          outerStrokeWidth: 16,
          innerStrokeWidth: 8,
          outerStrokeColor: '#78C000',
          innerStrokeColor: '#C7E596',
          animationDuration: 300,
      }),
    PaginationModule,
    AdvancedpaginationModules,
    NgbPaginationModule,
    NgxBarcodeModule,
    BarcodeGeneratorAllModule, QRCodeGeneratorAllModule, DataMatrixGeneratorAllModule,
    FroalaEditorModule.forRoot(), FroalaViewModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDAVGbrbgDGUd8PnpMQSY0YPC21yTiQZxc'
    }), ValidationerrorModule

  ],
    providers: [
        TokenService,
        AuthService,
        Authorisation,
        Localstorage,
        Securestorage,
        Lookup,
        AlertService,
        AuthGuard,
        ActivetabService,
        DatePipe,
        CircleProgressOptions,
        PopupService,
        RoleService,
        Emailverification

    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
