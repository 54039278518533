import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-queueservice',
  templateUrl: './queueservice.component.html',
  styleUrls: ['./queueservice.component.css']
})
export class QueueserviceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
