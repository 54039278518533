import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Securestorage} from './securestorage';


@Injectable()
export class Localstorage {
  constructor( private router: Router, private storageService: Securestorage) {
  }
  setStorage(key: string, value: any) {
    this.storageService.secureStorage.setItem(key,value);
  }
  getStorage(key: string) {
    return this.storageService.secureStorage.getItem(key);
  }
  clearStorage() {
    return this.storageService.secureStorage.clear();
  }


}

