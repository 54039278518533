import {Component, OnInit} from '@angular/core';
import {Http, Response} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {Lookup} from 'app/common/lookup';
import {Common} from 'app/common/common';


@Component({
    selector: 'app-updatetravelallowance',
    templateUrl: './updatetravelallowance.component.html',
    styleUrls: ['./updatetravelallowance.component.css']
})
export class UpdatetravelallowanceComponent implements OnInit {

    worksites = [];
    skillcategory = [];
    TravelallowancesForm: FormGroup;
    id: any;
    travelallowancesdetails: any = {};
    Utility = Utility;
    deleteTravelallowances = function (id) {
        if (confirm("Are you sure you want to delete this record?")) {
            this.travelallowances = {
                "id": id,
                "deleted_by": Utility.getLoggedUserId(),
            };
            this.http.post(Configuration.serviceCoreURL + "/deletetravelallowances", this.travelallowances).subscribe((res: Response) => {
                this.router.navigate(['/travelallowances']);
            });
        }
    };

    constructor(private http: Http, private router: Router, private route: ActivatedRoute,
                private fb: FormBuilder, private lookUp: Lookup) {
    }

    getLookUp() {
        this.lookUp.getPayrollSetUpLookUp('worksites').subscribe((data) => {
            if (data) {
                this.worksites = data;
            }
        });
        this.lookUp.getPayrollSetUpLookUp('skilledcategory').subscribe((data) => {
            if (data) {
                this.skillcategory = data;
            }
        });
    }

    updateFoodallowances(travelallowances) {
        if (this.TravelallowancesForm.valid) {
            travelallowances.id = this.id;
            travelallowances.modified_by = Utility.getLoggedUserId();
            this.http.post(Configuration.serviceCoreURL + "/updatetravelallowances", travelallowances).subscribe((res: Response) => {
                this.router.navigate(['/travelallowances']);
            });
        }
    }

    ngOnInit() {
        $('.navbar-collapse li.active').removeClass('active');
        $('#menupayroll').addClass('active');
        this.getLookUp();
        this.TravelallowancesForm = new FormGroup({
            skillcategory_id: new FormControl('', Validators.compose([Validators.required])),
            worksite_id: new FormControl('', Validators.compose([Validators.required])),
            amount: new FormControl('', Validators.compose([Validators.required])),
        });
        this.route.paramMap.subscribe(params => {
            this.id = Common.decryptId((params.get('id')));
        });
        this.http.get(Configuration.serviceCoreURL + "/travelallowancesdetails?id=" + this.id).subscribe(data => {
            this.travelallowancesdetails = data.json();
        });
    }

}
