import * as CryptoJS from 'crypto-js';
import {FormGroup} from '@angular/forms';

export class Common {
    public static showFooter: boolean = true;
    public static PageIndex :any= 1;
    public static Page:any = 1;
    public static totalPages;
    public static perPage;
    public static  fromPage;
    public static  startPage= 1;
    public static  PageSize() {

        return localStorage.getItem('pageSize');
    }
    public static AdvancePageSize(){
        return localStorage.getItem('advancepageSize');
    }
    public static  getFooterTotal(arrayLength, pageSize, event) {
        let total = Math.ceil(arrayLength / pageSize );
        if(total < event.target.value){
            this.showFooter = false;
        }else{
            this.showFooter = true;
        }
    }

    public static showchange(args){
    if(args.target.checked){
      $('.'+args.target.id).removeClass('show');
    }else{
      $('.'+args.target.id).addClass('show');
    }
  }

    public static  isNumberNanOrInfinity(value) {
        if (Number.isNaN(value) || value == Number.POSITIVE_INFINITY || value == Number.NEGATIVE_INFINITY) {
            return 0;
        }else {
            return value;
        }
    }
    public static encryptId(id) {
        try {
            return CryptoJS.AES.encrypt(JSON.stringify(id), 'masterKey').toString();
        } catch (e) {
        }
    }

    public static decryptId(data) {
        try {
            const bytes = CryptoJS.AES.decrypt(data, 'masterKey');
            if (bytes.toString()) {
                return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            }
        } catch (e) {
        }
    }

    public static noOfMonths(timeperiod) {
        if (timeperiod == 'MONTHLY') {
            return 1;
        } else if (timeperiod == 'TRIANNUAL') {
            return 4;
        } else if (timeperiod == 'QUARTERLY') {
            return 3;
        } else if (timeperiod == 'HALF YEARLY') {
            return 6;
        } else if (timeperiod == 'YEARLY') {
            return 12;
        }else if (timeperiod == 'TWO YEARLY') {
            return 24;
        }
    }
    public static noOfWeeks(timeperiod){
        if(timeperiod == 'WEEKLY'){
            return 1;
        }
    }
    public static getRoute(route){
        if(route.lastIndexOf('/')==0){
            return route;
        }else {
            return route.substr(0, route.lastIndexOf('/'));
        }
    }

    public static addValidators(form: FormGroup, validation) {
        for (const key in form.controls) {
            form.get(key).setValidators(validation[key]);
            form.get(key).updateValueAndValidity();
        }
    }

    public static removeValidators(form: FormGroup) {
        for (const key in form.controls) {
            form.get(key).clearValidators();
            form.get(key).updateValueAndValidity();
        }
    }
    public static disabledButton() {
        $('#btn_save').css('pointer-events', 'none');
    }
    public static enabledButton() {
        $('#btn_save').css('pointer-events', 'auto');
    }
}
