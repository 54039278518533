import {Component, OnInit} from '@angular/core';
import {Http} from '@angular/http';
import {Title} from '@angular/platform-browser';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {Lookup} from 'app/common/lookup';
import {Angular2Csv} from 'angular2-csv';
import {DatePipe} from '@angular/common';
import {HttpClient} from "@angular/common/http";
declare var $: any;
@Component({
    selector: 'app-processingcenterlog',
    templateUrl: './processingcenterlog.component.html',
    styleUrls: ['./processingcenterlog.component.css']
})
export class ProcessingcenterlogComponent implements OnInit {
  constructor(private http: HttpClient, private httpclient: HttpClient,  private title: Title, private lookUp: Lookup, private datePipe: DatePipe) {
  }
    searchText;
    p: any = 1;
    count: any = 20;
    months = [];
    year = [];
  payrolllogdetails;
  customers = [];
  categories = [];
  payrolllogs;
  business_id = localStorage.getItem('businessid');
    getLookUp = function () {
        this.lookUp.getProcessingCenterlookup('months').subscribe((data) => {if (data) {this.months = data;}});
        this.lookUp.getProcessingCenterlookup('year').subscribe((data) => {if (data) {this.year = data;}});
      this.lookUp.getProcessingCenterlookup('clientswithcompanyname', this.business_id).subscribe((data) => {
        if (data) {
          this.customers = data;
          for (let customers of this.customers) {
            customers['customername'] = customers.companyname;
          }
        }
      });
      this.lookUp.getProcessingCenterlookup('categories').subscribe((data) => {if (data) {this.categories = data;}});
    };
  getpayrolllogList () {
    this.http.get(Configuration.serviceCoreURL + '/payrolllogs').subscribe(data => {
      this.payrolllogdetails = data;
      //console.log(this.payrolllogdetails);
    });
  }
  getpayrollcentrallogList () {
    this.http.get(Configuration.serviceCoreURL + '/payrollcentrallogs').subscribe(data => {
      this.payrolllogs = data;
      //console.log(this.payrolllogdetails);
    });
  }
  insertPayslip (process) {
    process.modified_by = Utility.getLoggedUserId();
    process.created_by = Utility.getLoggedUserId();
    this.http.post(Configuration.serviceCoreURL + '/insertpayslip', process).subscribe(res => {
      $('#frmProcessing').get(0).reset();
    });
  }
  // getCustomerId(args) {
  //   console.log(args.value['id']);
  //   this.customer_id = args.value['id'];
  // }
  insertServiceInvoice (serviceinvoice) {
    serviceinvoice.modified_by = Utility.getLoggedUserId();
    serviceinvoice.created_by = Utility.getLoggedUserId();
    this.http.post(Configuration.serviceCoreURL + '/serviceinvoiceprocessing', serviceinvoice).subscribe(res => {
      $('#frmServiceInvoice').get(0).reset();
    });
  }


    ngOnInit() {

      $(document).ready(function () {
        $('#serviceinvoices').click(function () {
          $('.serviceinvoice').show();
          $('.payrollrun').hide();
          $('a').removeClass('active');
          $(this).addClass('active');
        });
        $('#payrollruns').click(function () {
          $('.serviceinvoice').hide();
          $('.payrollrun').show();
          $('a').removeClass('active');
          $(this).addClass('active');
        });
      });
        this.getLookUp();
        this.getpayrolllogList();
        this.getpayrollcentrallogList();
    }

}
