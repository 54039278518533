export class Validate {

  public static isNumber(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  public  static checkIsMeasurementFormula (event: any){
    const pattern = /[+/*-]+[0-9]/;
    if (pattern.test(event)) {
      return true;
    }else{
      return false;
    }
  }
  public  static isMeasurementFormula (event: any){
    const inputChar = String.fromCharCode(event.charCode);
    const pattern = /[0-9+*-/]/;
    if ( (event.keyCode !== 8) && (!pattern.test(inputChar))) {
      event.preventDefault();
    }
  }

  public static isDecimal(event: any) {
    //console.log(event); // for testing
    const pattern = /[0-9\+\.\ ]/; // kritika
    // const pattern = /^[0-9]+\.?/; // ashish
    // const pattern = /^[0-9]+(\.[0-9]+)?/;
    // const pattern = /^[-+]?[0-9]+\.[0-9]+$/;
    // const pattern = /^((?:\d|\d{1,3}(?:,\d{3})){0,6})(?:\.\d{1,2}?)?$/;
    const inputChar = String.fromCharCode(event.charCode);
    // (event.keyCode !== 8) &&
    // if (((event.keyCode !== 65 || event.keyCode !== 86 || event.keyCode !== 67) && (event.ctrlKey !== true || event.metaKey !== true)) && (!pattern.test(inputChar))) {

    if ( (event.keyCode !== 8) && (!pattern.test(inputChar))) {
      event.preventDefault();
    }
  }
  public static isPercentageRange(event: any) {
    let current: string = event.target.value;
    const position = event.target.selectionStart;
    const next: string = [
      current.slice(0, position),
      event.key == 'Decimal' ? '.' : event.key,
      current.slice(position)
    ].join('');
    const beforeDecimal: any = next.split('.')[0];
    const AfterDecimal: any = next.split('.')[1];
    const checkDotFirstPosition: string = next.substring(0, 1);
    if (next === '00') {
      event.preventDefault();
    }
    if (checkDotFirstPosition === '.') {
      event.preventDefault();
    }
    if (beforeDecimal) {
      if (beforeDecimal <= 100) {
        if (beforeDecimal.toString().length >= 4) {
          event.preventDefault();
        }
      } else {
        if (beforeDecimal.toString().length >= 3) {
          event.preventDefault();
        }
      }
    }
    if (AfterDecimal) {
      if (AfterDecimal.toString().length >= 3) {
        event.preventDefault();
      }
    }
    if (next && !String(next).match(new RegExp(/^\d{0,2}\.?\d{0,2}$/g))) {
      event.preventDefault();
    }
  }

}



