import {Component, OnInit} from '@angular/core';
import {Http} from '@angular/http';
import {Title} from '@angular/platform-browser';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {Common} from '../../../../common/common';

@Component({
    selector: 'app-travelallowancelist',
    templateUrl: './travelallowancelist.component.html',
    styleUrls: ['./travelallowancelist.component.css']
})
export class TravelallowancelistComponent implements OnInit {

    travelallowances = [];
    Common = Common;
    searchText;
    p: any = 1;

    constructor(private http: Http, private title: Title) {
    }

    getTravelAllowances() {
        this.http.get(Configuration.serviceCoreURL + '/travelallowances').subscribe(data => {
            this.travelallowances = data.json();
        });
    }

    ngOnInit() {
        this.title.setTitle('Octanics - Travel Allowance');
        this.getTravelAllowances();
    }


}
