import { Injectable } from '@angular/core';
import {Utility} from 'app/common/utility';
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
@Injectable()
export class Emailverification {
    constructor(private http: HttpClient) {
        // console.log(Utility.getabstractKey());
    }
    emailVerified(email){
       if( email != null && email != '' && email != undefined) {
         return this.http.get(Utility.serviceIntegrationURL() + '/emailverification?key='+ Utility.getabstractKey()+'&email='+email).pipe(map(res => res));
       }
       else {
         return new Observable(subscriber => {
           subscriber.next({'deliverability': null, 'quality_score' : null});
           subscriber.complete();
         });
         // return Observable {_isScalar: false, source: Observable, operator: MapOperator}
         // operator: MapOperator {thisArg: undefined, project: ƒ}
         // source: Observable {_isScalar: false, source: Observable, operator: MapOperator}
         // _isScalar: false
         //   [[Prototype]]: Object
       }
    }
}
