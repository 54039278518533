import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {FormGroup, Validators, FormControl} from '@angular/forms';
import * as CryptoJS from 'crypto-js';
import {Common} from 'app/common/common';
import {HttpClient} from "@angular/common/http";

declare var $: any;

@Component({
    selector: 'app-updatescmprocess',
    templateUrl: './updatescmprocess.component.html',
    styleUrls: ['./updatescmprocess.component.css']
})
export class UpdatescmprocessComponent implements OnInit {

    constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute) {
    }

    ScmProcessForm: FormGroup;
    id: any;
    scmprocessdetail: any = {};
    deletescmprocess = function (id) {
        if (confirm("Are you sure you want to delete this record?")) {

            this.scmprocess = {
                "id": id,
                "deleted_by": Utility.getLoggedUserId(),
            };
            this.http.post(Configuration.serviceCoreURL + "/deletescmprocess", this.scmprocess).subscribe((res: Response) => {
                this.router.navigate(['/scmprocess']);
            });
        }
    };
    updateScmProcess = function (scmprocess) {
        if (this.ScmProcessForm.valid) {
            Common.disabledButton();
            scmprocess.id = this.id;
            scmprocess.modified_by = Utility.getLoggedUserId();

            this.http.post(Configuration.serviceCoreURL + "/updatescmprocess", scmprocess).subscribe(

            (response) => {
              this.router.navigate(['/scmprocess']);
            },
            (error) => {
              Common.enabledButton();
            });
            }
            else{
              this.alertService.validationAlert();
            }
            };

     ngOnInit() {
        $('.navbar-collapse li.active').removeClass('active');
        $('#menumaster').addClass('active');
        this.ScmProcessForm = new FormGroup({
            name: new FormControl('', Validators.compose([
                Validators.required
            ]))
        });
      this.route.paramMap.subscribe(params => {
        this.id =  Common.decryptId((params.get('id')));
      });
        this.http.get(Configuration.serviceCoreURL + "/scmprocessdetails?id=" + this.id).subscribe(data => {
            this.scmprocessdetail = data;
        });
    }


}
