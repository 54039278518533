import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Common} from '../common/common';
import {Validate} from '../common/validate';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
declare var $: any;
@Component({
  selector: 'app-pagination',
  inputs: [ 'itemCount', 'pageSize', 'pageIndex' ],
  outputs: [ 'pageIndexChange', 'pageSizeChange' ],
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Output() pagination = new EventEmitter();
  pageIndexChange = new EventEmitter();
  pageSizeChange = new EventEmitter();
  constructor() {
    this.pageSize = 1;
    this.pageIndex = 1;
    // this.changeNoRecordsPerPage();
    // this.goToPage(1);
  }
  Utility= Utility;
  _pageIndex: any;
  page: any;
  _itemCount: any;
  _pageSize: any;
  _recordsInPage: any;
  _pageCount: any;
  PageChange(pag: number) {
    this.pagination.emit({valor: pag});
  }
  SizeChange(pag: number) {
    if(this._itemCount <= 100 && this._pageIndex >1){
      this.pageIndex=1;
    }
    this.pagination.emit({valor: pag});
  }
  get itemCount() {
    return this._itemCount;
  }
  changeNoRecordsPerPage() {
    if(this.itemCount <= this._pageSize){
      this._recordsInPage = this.itemCount;
    } else {
      if(this.pageIndex < this._pageCount) {
        this._recordsInPage = this._pageSize * (this.pageIndex);
      } else {
        let difference =  Math.ceil(this.itemCount-this._recordsInPage);
        this._recordsInPage = this._recordsInPage + difference;
      }
    }
    if(this._pageIndex == this._pageCount) {
      $('#prev-btn').removeClass("disabled-btn");
    }
  }
  set itemCount(value) {
    this._itemCount = value;
    this.updatePageCount();
  }
  get pageSize() {
    return this._pageSize;
  }
  set pageSize(value) {
    this._pageSize = value;
    this.pageSizeChange.emit(this._pageSize);
    this.updatePageCount();
  }

  updatePageCount() {
    this._pageCount = Math.ceil(this.itemCount / this.pageSize);
    this.changeNoRecordsPerPage();
  }

  get pageIndex() {
    return this._pageIndex;
  }
  set pageIndex(value) {
    this._pageIndex = value;
    this.pageIndexChange.emit(this.pageIndex);
  }

  get canMoveToNextPage() : boolean {
    return this.pageIndex < this._pageCount ? true : false;
  }
  get canMoveToPreviousPage() : boolean {
    return this.pageIndex > 1 ? true : false;
  }
  goToPage(page) {
    if(page !== '' && !isNaN(this.pageIndex) && page !=='0'){
      this.pageIndex = page;
      this.PageChange( page);
    }
    else{
      this.pageIndex = 1;
      this.PageChange(  this.pageIndex);
    }
    this.changeNoRecordsPerPage();
  }

  moveToNextPage() {
    if (this.canMoveToNextPage) {
      this.pageIndex++;
      this.PageChange(this.pageIndex);
      // this.SizeChange(this._pageSize);
      this.changeNoRecordsPerPage();
    }
  }

  moveToPreviousPage(page) {
    if (this.canMoveToPreviousPage) {
      this.pageIndex--;
      this.PageChange(page);
      // this.SizeChange(this._pageSize);
      this.changeNoRecordsPerPage();
    }
  }

  //   @Output() pagination = new EventEmitter();
  // pageIndexChange = new EventEmitter();
  // constructor() {
  //   this.pageSize = 1;
  // }
  // Utility= Utility;
  // _pageIndex: number;
  // page: number;
  // _itemCount: number;
  // _pageSize: number;
  // _pageCount: number;
  //   PageChange(pag: number) {
  //       this.pagination.emit({valor: pag});
  //   }
  // get itemCount() {
  //   return this._itemCount;
  // }
  // set itemCount(value) {
  //   this._itemCount = value;
  //   this.updatePageCount();
  // }
  // get pageSize() {
  //   return this._pageSize;
  // }
  // set pageSize(value) {
  //   this._pageSize = value;
  //   this.updatePageCount();
  // }
  //
  // updatePageCount() {
  //   this._pageCount = Math.ceil(this.itemCount / this.pageSize);
  // }
  //
  // get pageIndex() {
  //   return this._pageIndex;
  // }
  // set pageIndex(value) {
  //   this._pageIndex = value;
  //   this.pageIndexChange.emit(this.pageIndex);
  // }
  //
  // get canMoveToNextPage() : boolean {
  //   return this.pageIndex < this._pageCount - 1 ? true : false;
  // }
  // get canMoveToPreviousPage() : boolean {
  //   return this.pageIndex >= 1 ? true : false;
  // }
  // goToPage(page) {
  //   if(page !== '' && !isNaN(this.pageIndex) && page !=='0'){
  //     this.pageIndex = page-1;
  //       this.PageChange(  page);
  //   }
  //   else{
  //     this.pageIndex = 0;
  //       this.PageChange(  this.pageIndex);
  //   }
  // }
  //
  // moveToNextPage() {
  //   if (this.canMoveToNextPage) {
  //     this.pageIndex++;
  //       this.PageChange( this.pageIndex + 1);
  //   }
  // }
  //
  // moveToPreviousPage(page) {
  //   if (this.canMoveToPreviousPage) {
  //     this.pageIndex--;
  //     this.PageChange(page);
  //   }
  // }

    // @Input()
    // total: number = 0;
    //
    // @Input()
    // page: number = 1;
    //
    // @Output()
    // goTo: EventEmitter<number> = new EventEmitter<number>();
    //
    // constructor() { }
    //
    // totalPages() {
    //     return Math.ceil(this.total / 10);
    // }
    //
    // pagesRange() {
    //     return this.range(1, this.totalPages() + 1);
    // }
    //
    // prevPage() {
    //     return Math.max(1, this.page - 1);
    // }
    //
    // nextPage() {
    //     return Math.min(this.totalPages(), this.page + 1);
    // }
    //
    // pageClicked(page: number) {
    //     this.goTo.next(page);
    // }
    //
    // range(start, stop, step=1){
    //     if (!stop) { start=0;stop=start; }
    //     return Array.from(new Array(Number((stop-start)/step)), (x,i) => start+ i*step)
    // }
}

