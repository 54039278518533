import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable()
export class ActivetabService {
  @Output() modifyActiveTabEvent = new EventEmitter<string>();

  modifyActiveTab(data: string) {
    this.modifyActiveTabEvent.emit(data);
  }
}
