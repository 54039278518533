import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {Performancekra} from "../../../models/performancekra.model";
import {HttpClient} from "@angular/common/http";

@Component({
    selector: 'app-updateperformancekra',
    templateUrl: './updateperformancekra.component.html',
    styleUrls: ['./updateperformancekra.component.css']
})
export class UpdateperformancekraComponent implements OnInit {

    constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute) {
    }

    id: any;
    kradetail: any = {};

    updateperformancekra = function (kradeatils) {
        kradeatils.id = this.id;
        kradeatils.modified_by = Utility.getLoggedUserId();

        this.http.post(Configuration.serviceCoreURL + "/updateperformancekra", kradeatils).subscribe((res: Response) => {
            this.router.navigate(['/performancekpi']);
        });
    };

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.id = +params['id'];
        });
        this.http.get(Configuration.serviceCoreURL + "/performancekradetails?id=" + this.id).subscribe(data => {
            this.kradetail = data;
        });
    }

}
