import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from "@angular/forms";
import {AdvancedpaginationComponent} from "./advancedpagination.component";

@NgModule({
    declarations: [
        AdvancedpaginationComponent,
    ],
    exports: [
        AdvancedpaginationComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
    ]
})
export class AdvancedpaginationModules { }