import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable()
export class PopupService {
  @Output() submitPopupEvent = new EventEmitter<string>();

  submitPopup(data: string) {
    this.submitPopupEvent.emit(data);
  }
}
