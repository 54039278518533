import { Injectable } from '@angular/core';

import { Router } from '@angular/router';

import {Http} from '@angular/http';


@Injectable()
export class AuthService {

//  _userActionOccured: Subject<any> = new Subject();

  constructor(private router: Router, private http: Http) { }
  // login() {
  //   this.isLoggedIn = true;
  //   if(this.redirectUrl) {
  //     this.router.navigate([this.redirectUrl]);
  //   }
  //   this.router.navigate(['/']);  }

  // logout(): Observable<boolean> {
  //   this.isLoggedIn.next(false);
  //   return this.isLoggedIn$;
  // }

  // get userActionOccured(): Observable<void> { return this._userActionOccured.asObservable(); }
  //
  // notifyUserAction() {
  //   this._userActionOccured.next();
  // }

}

