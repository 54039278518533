import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {Title} from '@angular/platform-browser';
import {DatePipe} from '@angular/common';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {Scmprocess} from 'app/models/scmprocess.model';
import * as CryptoJS from 'crypto-js';
import {Common} from 'app/common/common';
import {Sorting} from '../../../../common/sorting';
import {HttpClient} from "@angular/common/http";

declare var $: any;

@Component({
    selector: 'app-scmprocesslist',
    templateUrl: './scmprocesslist.component.html',
    styleUrls: ['./scmprocesslist.component.css']
})
export class ScmprocesslistComponent implements OnInit {

    constructor(private http: HttpClient, private title: Title) {
    }

    searchText;
    scmprocess: Scmprocess;
    scmprocesses : any={};
    p:any = 1;
    count:any = 20;
    Common = Common;
    Sorting = Sorting;
    order: boolean = true;
    property: any = null;

  sorting(property, obj) {
    if (property == this.property) {
      this.property = property;
      this.order = !this.order;
      if (this.order) {
        $('.table-borderless thead th i').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
        $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-up').css('color', '1E2859');
      } else {
        $('.table-borderless thead th i').removeClass('fad fa-sort-up').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
        $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-down').css('color', '1E2859');
      }
      Sorting.sort(property, obj, this.order);
    } else {
      this.property = property;
      this.order = false;
      $('.table-borderless thead th i').removeClass('fad fa-sort-down').addClass('fad fa-sort').css('color', '1E2859');
      $('.table-borderless thead #' + property + ' i').removeClass('fad fa-sort').addClass('fad fa-sort-down').css('color', '1E2859');
      Sorting.sort(property, obj, this.order);
    }
  }

    getList = function () {
        this.http.get(Configuration.serviceCoreURL + "/scmprocess").subscribe(data => {
            this.scmprocesses = data;
        });
    };


    ngOnInit() {
        $('.navbar-collapse li.active').removeClass('active');
        $('#menumaster').addClass('active');
        this.getList();
        this.title.setTitle('Octanics - Scm Process');
    }

}
