import { Component, OnInit } from '@angular/core';
import {Http, Response} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {Lookup} from 'app/common/lookup';

@Component({
  selector: 'app-inserttravelallowance',
  templateUrl: './inserttravelallowance.component.html',
  styleUrls: ['./inserttravelallowance.component.css']
})
export class InserttravelallowanceComponent implements OnInit {

    worksites = [];
    skillcategory = [];
    TravelallowancesForm: FormGroup;
    Utility = Utility;

    getLookUp = function(){
        this.lookUp.getPayrollSetUpLookUp('worksites').subscribe((data) => {if (data) {this.worksites = data; }});
        this.lookUp.getPayrollSetUpLookUp('skilledcategory').subscribe((data) => {if (data) {this.skillcategory = data; }});
    };
    insertTravelallowances = function (travelallowances) {
        if (this.TravelallowancesForm.valid) {
            travelallowances.created_by = Utility.getLoggedUserId();
            travelallowances.modified_by = Utility.getLoggedUserId();
            this.http.post(Configuration.serviceCoreURL + "/inserttravelallowances", travelallowances).subscribe((res: Response) => {
                this.router.navigate(['/travelallowances']);
            });
        }
    };

    constructor(private http: Http, private router: Router,
                private route: ActivatedRoute, private fb: FormBuilder, private lookUp: Lookup) {
    }

    ngOnInit() {

        $('.navbar-collapse li.active').removeClass('active');
        $('#menupayroll').addClass('active');
        this.getLookUp();
        this.TravelallowancesForm = new FormGroup({
            skillcategory_id:new FormControl('', Validators.compose([Validators.required])),
            worksite_id: new FormControl('', Validators.compose([Validators.required])),
            amount: new FormControl('', Validators.compose([Validators.required])),
        });
    }
}
